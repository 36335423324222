import React from 'react';
import dayjs from 'dayjs';
import { Button, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { WarehouseOrder } from '../../../../custom_types/warehouse-order-page';
import { FulfillmentStatus, PaymentStatus, UI_ROUTES } from '../../../../shared/utils/constants';

const columns = [
  {
    title: 'Number',
    key: 'number',
    dataIndex: 'number'
  },
  {
    title: 'Date',
    key: 'dateCreated',
    render: (_: any, record: WarehouseOrder) => {
      return (
        <>
          <div>{dayjs(record.createdDate).format('MMM DD, YYYY')}</div>
          <div>{dayjs(record.createdDate).format('HH:mm A')}</div>
        </>
      );
    }
  },
  {
    title: 'Customer',
    key: 'buyerInfo',
    render: (_: any, record: WarehouseOrder) => {
      return (
        <span>{`${record.recipientInfo?.contactDetails.firstName} ${record.recipientInfo?.contactDetails.lastName}`}</span>
      );
    }
  },
  {
    title: 'Website',
    key: 'website',
    dataIndex: 'website'
  },
  {
    title: 'Payment status',
    key: 'paymentStatus',
    render: (_: any, record: WarehouseOrder) => {
      const text = record.paymentStatus === PaymentStatus.PAID ? 'Paid' : 'Unpaid';
      return (
        <Tag color={record.paymentStatus === PaymentStatus.PAID ? '' : 'yellow'}>
          <FormattedMessage id={text} />
        </Tag>
      );
    }
  },
  {
    title: 'Fulfillment status',
    key: 'fulfillmentStatus',
    render: (_: any, record: WarehouseOrder) => {
      let text = 'Unfulfilled';
      if (record.fulfillmentStatus === FulfillmentStatus.FULFILLED) {
        text = 'Fulfilled';
      }
      if (record.fulfillmentStatus === FulfillmentStatus.FULFILLING) {
        text = 'Fulfilling';
      }
      let colorText = '';
      if (record.fulfillmentStatus === FulfillmentStatus.NOT_FULFILLED) {
        colorText = '#fb7d33';
      }
      if (record.fulfillmentStatus === FulfillmentStatus.FULFILLING) {
        colorText = 'yellow';
      }
      return (
        <Tag color={colorText}>
          <FormattedMessage id={text} />
        </Tag>
      );
    }
  },
  {
    title: 'Items',
    key: 'lineItems',
    render: (_: any, record: WarehouseOrder) => {
      return (
        <span>
          {record.lineItems.length > 1
            ? `${record.lineItems.length} items`
            : `${record.lineItems.length} item`}
        </span>
      );
    }
  }
];

export default columns;
