import { Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { UI_ROUTES } from '../../../shared/utils/constants';

const { TabPane } = Tabs;

const OverseaWarehousePage = (): ReactElement => {
  const location = useLocation();
  return (
    <div>
      <PageHeader title="Oversea Warehouse" />
      <Tabs
        type="card"
        defaultActiveKey={`${UI_ROUTES.OVERSEA_WAREHOUSE}${UI_ROUTES.PROFILE}`}
        activeKey={location.pathname}
      >
        <TabPane
          key={`${UI_ROUTES.OVERSEA_WAREHOUSE}${UI_ROUTES.PROFILE}`}
          tab={
            <Link to={`${UI_ROUTES.ACCOUNT}${UI_ROUTES.PROFILE}`}>
              <FormattedMessage id="profile" />
            </Link>
          }
        >
          <div>
            <h1>Oversea Warehouse</h1>
          </div>
        </TabPane>
        {/* <TabPane
          key={`${UI_ROUTES.ACCOUNT}${UI_ROUTES.BILLING}`}
          tab={
            <Link to={`${UI_ROUTES.ACCOUNT}${UI_ROUTES.BILLING}`}>
              <FormattedMessage id="billing" />
            </Link>
          }
        >
          <BillingPage />
        </TabPane> */}
      </Tabs>
    </div>
  );
};

export default OverseaWarehousePage;
