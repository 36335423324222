import React from 'react';
import { Card } from 'antd';
import { WarehouseOrder } from '../../../custom_types/warehouse-order-page';

interface WarehouseOrderInfoCardProps {
  order: WarehouseOrder;
}

const WarehouseOrderInfoCard = ({ order }: WarehouseOrderInfoCardProps) => {
  return (
    <div>
      <Card size="small" title="Contact information">
        <p>{order.buyerInfo.email}</p>
      </Card>
      <Card size="small" title="Shipping address">
        <p>{`${order.recipientInfo?.contactDetails.firstName} ${order.recipientInfo?.contactDetails.lastName}`}</p>
        <p>{order.recipientInfo?.address.addressLine}</p>
        {order.recipientInfo?.address.addressLine2 && (
          <p>{order.recipientInfo?.address.addressLine2}</p>
        )}
        <p>{`${order.recipientInfo?.address.city}, ${
          order.recipientInfo?.address.subdivision?.split('-')[1]
        } ${order.recipientInfo?.address.postalCode}`}</p>
        <p>{order.recipientInfo?.address.countryFullname}</p>
      </Card>
      <Card size="small" title="Shipping option">
        <p>{order.shippingInfo.title}</p>
        <p>{order.shippingInfo.region.name}</p>
      </Card>
    </div>
  );
};

export default WarehouseOrderInfoCard;
