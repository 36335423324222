import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Result,
  Row,
  Space,
  Spin,
  Table,
  Tag
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { fulfillItems, selectWarehouseOrders } from '../../../redux/orders/warehouseOrderSlice';
import { FulfillMent, LineItem, WarehouseOrder } from '../../../custom_types/warehouse-order-page';
import { FulfillmentStatus, UI_ROUTES } from '../../../shared/utils/constants';

interface ParamType {
  orderId: string | undefined;
  index: string | undefined;
}

const WarehouseFulfillPage = () => {
  const { orderId, index } = useParams<ParamType>();
  const [form] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const warehouseOrders = useSelector(selectWarehouseOrders);
  const [loading, setLoading] = useState(false);
  const [curOrder, setCurOrder] = useState<undefined | WarehouseOrder>();
  const [curFulfillment, setCurFulfillment] = useState<FulfillMent | undefined>();

  useEffect(() => {
    setLoading(true);
    const order = warehouseOrders.find((item) => item.id === orderId);
    if (!order) {
      history.push(UI_ROUTES.ORDERS);
      return;
    }
    if (!index) {
      history.push(`${UI_ROUTES.ORDERS}/${order.id}`);
      return;
    }
    const fulfillment = order?.fulfillments[parseInt(index, 10)];
    if (!fulfillment) {
      history.push(`${UI_ROUTES.ORDERS}/${order.id}`);
      return;
    }
    setCurOrder(order);
    setCurFulfillment(fulfillment);
    setLoading(false);
  }, [warehouseOrders, orderId, history, index]);

  const columns = [
    {
      key: 'image',
      render: (_: any, record: LineItem) => (
        <img
          src={record.image?.url || 'https://via.placeholder.com/150'}
          alt={record.productName.original}
          style={{ width: '50px', height: '50px' }}
        />
      )
    },
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    // {
    //   title: 'Type',
    //   key: 'lineItemType',
    //   dataIndex: 'lineItemType'
    // },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    }
  ];

  const cardTitle = () => {
    return (
      <div>
        <span>
          {curFulfillment?.status === FulfillmentStatus.FULFILLED ? (
            <Tag color="geekblue">
              <CheckCircleOutlined rev={undefined} /> Fulfilled{' '}
            </Tag>
          ) : (
            <Tag color="yellow">Unfulfilled</Tag>
          )}
        </span>
      </div>
    );
  };

  const submitFullfillment = (values: any) => {
    console.log(values);
    if (!curFulfillment || !orderId || !index) return;
    const fulfillment: FulfillMent = { ...curFulfillment };
    if (!fulfillment.trackingInfo) {
      fulfillment.trackingInfo = {
        shippingProvider: '',
        trackingNumber: '',
        trackingLink: '',
        lable: ''
      };
    }
    fulfillment.dateCreated = new Date();
    fulfillment.trackingInfo.shippingProvider = values.shippingProvider;
    fulfillment.trackingInfo.trackingNumber = values.trackingNumber;
    const data = { orderId, index: parseInt(index, 10), fulfillment };
    dispatch(fulfillItems(data));
    history.push(`${UI_ROUTES.ORDERS}${UI_ROUTES.DETAIL}/${orderId}`);
  };

  return (
    <div style={{ marginLeft: '100px', marginRight: '100px' }}>
      <div>
        <Breadcrumb style={{ maxWidth: '200px', float: 'left', marginTop: '10px' }}>
          <Breadcrumb.Item>
            <Link to={UI_ROUTES.ORDERS}>
              <FormattedMessage id="orders" />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`${UI_ROUTES.ORDERS}${UI_ROUTES.DETAIL}/${curOrder?.id}`}>
              # {curOrder?.number}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <FormattedMessage id="fulfillment" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Divider />
      <Spin spinning={loading} size="large" style={{ margin: 'auto' }}>
        <div>
          {curOrder && curFulfillment ? (
            <>
              <Row style={{ marginTop: '30px' }} gutter={20}>
                <Col span={16}>
                  <Card size="small" title={cardTitle()} style={{ marginBottom: '20px' }}>
                    <Table<LineItem>
                      columns={columns}
                      dataSource={curFulfillment.items}
                      rowKey={(record) => record.physicalProperties.sku}
                      pagination={false}
                    />
                    <h3>Tracking information</h3>
                    <Form form={form} onFinish={submitFullfillment}>
                      <Space>
                        <Form.Item name="shippingProvider" label="Shipping Provider">
                          <Input type="text" />
                        </Form.Item>
                        <Form.Item name="trackingNumber" label="Tracking Number">
                          <Input type="text" />
                        </Form.Item>
                      </Space>
                      <Form.Item name="label">
                        <Button type="primary" htmlType="submit">
                          Fulfill items
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card size="small" title="Shipping address" style={{ marginBottom: '20px' }}>
                    <p>{`${curOrder.shippingInfo.logistics.shippingDestination?.contactDetails.firstName} ${curOrder.shippingInfo.logistics.shippingDestination?.contactDetails.lastName}`}</p>
                    <p>
                      {curOrder.shippingInfo.logistics.shippingDestination?.address.addressLine}
                    </p>
                    {curOrder.shippingInfo.logistics.shippingDestination?.address.addressLine2 && (
                      <p>
                        {curOrder.shippingInfo.logistics.shippingDestination?.address.addressLine2}
                      </p>
                    )}
                    <p>{`${curOrder.shippingInfo.logistics.shippingDestination?.address.city}, ${
                      curOrder.shippingInfo.logistics.shippingDestination?.address.subdivision?.split(
                        '-'
                      )[1]
                    } ${
                      curOrder.shippingInfo.logistics.shippingDestination?.address.postalCode
                    }`}</p>
                    <p>
                      {curOrder.shippingInfo.logistics.shippingDestination?.address.countryFullname}
                    </p>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Result status="warning" title="No Fulfillment Found" />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default WarehouseFulfillPage;
