import { Form, Modal, Select } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProForm, ProFormDependency, ProFormSelect } from '@ant-design/pro-components';
import { selectWarehouseCarriers } from '../../../redux/orders/warehouseOrderSlice';
import { IService, WarehouseCarrier } from '../../../custom_types/warehouse-order-page';

interface FulfillmentLabelModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: (warehouseCarrier: WarehouseCarrier, service: IService) => void;
}

const { Option } = Select;

const FulfillmentLabelModal = ({ show, onClose, onConfirm }: FulfillmentLabelModalProps) => {
  const warehouseCarriers = useSelector(selectWarehouseCarriers);

  const warehouseCarrierOptions = warehouseCarriers.map((carrier) => {
    return { value: carrier.id, label: carrier.name };
  });

  const getServiceOptions = (carrierId: string) => {
    const warehouseCarrier = warehouseCarriers.find((carrier) => carrier.id === carrierId);
    if (warehouseCarrier) {
      return warehouseCarrier.services.map((service) => {
        return { value: service.key, label: service.name };
      });
    }
    return [];
  };

  const formSubmmitHandler = async (values: any) => {
    console.log(values);
    const carrierId = values.warehouseCarrier;
    const serviceKey = values.service;

    const warehouseCarrier = warehouseCarriers.find((carrier) => carrier.id === carrierId);
    if (warehouseCarrier) {
      const serviceObj = warehouseCarrier.services.find((service) => service.key === serviceKey);
      if (serviceObj) {
        console.log(warehouseCarrier, serviceObj);
        onConfirm(warehouseCarrier, serviceObj);
      }
    }
  };

  return (
    <Modal title="Create Fulfillment Label" open={show} footer={null} onCancel={() => onClose()}>
      <ProForm layout="vertical" onFinish={formSubmmitHandler}>
        <ProFormSelect
          options={warehouseCarrierOptions}
          name="warehouseCarrier"
          label="仓库物流账号"
          width="md"
        />
        <ProFormDependency name={['warehouseCarrier']}>
          {({ warehouseCarrier }) => {
            return (
              <ProFormSelect
                options={getServiceOptions(warehouseCarrier)}
                width="md"
                name="service"
                label="物流服务"
              />
            );
          }}
        </ProFormDependency>
      </ProForm>
    </Modal>
  );
};

export default FulfillmentLabelModal;
