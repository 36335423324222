import React, { useEffect } from 'react';
import { Button, Card, Form, Input, Space, Timeline } from 'antd';
import dayjs from 'dayjs';
import { useForm } from 'antd/es/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { WarehouseOrder } from '../../../custom_types/warehouse-order-page';
import { addOrderNote, selectOrderNoteLoading } from '../../../redux/orders/warehouseOrderSlice';

interface WarehouseOrderActivityCardProps {
  order: WarehouseOrder;
}

const WarehouseOrderActivityCard = ({ order }: WarehouseOrderActivityCardProps) => {
  const [timelineItems, setTimelineItems] = React.useState([] as any[]);
  const [form] = useForm();
  const orderNoteLoading = useSelector(selectOrderNoteLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (order) {
      const tmpItems = [
        {
          children: `${dayjs(order.createdDate).format('MMM DD, YYYY HH:mm A')} - Order placed`
        }
      ];
      if (order.fullfillmentRecords) {
        order.fullfillmentRecords.forEach((record) => {
          tmpItems.push({
            children: `${dayjs(record.dateCreated).format('MMM DD, YYYY HH:mm A')} - ${
              record.lineItems && record.lineItems.length > 0
                ? `${record.user.name} fulfilled ${record.lineItems.length} item`
                : `${record.user.name} ${record.description}`
            }`
          });
        });
      }
      setTimelineItems(tmpItems);
    }
  }, [order]);

  const handleAddNote = (values: any) => {
    if (values.note) {
      dispatch(addOrderNote(order.id, values.note));
    }
    form.resetFields();
  };

  return (
    <div>
      <Card size="small" title="Order activity" style={{ marginTop: '20px' }}>
        <Form form={form} onFinish={handleAddNote}>
          <Space>
            <Form.Item name="note">
              <Input type="text" style={{ width: '300px', marginRight: '20px' }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={orderNoteLoading}>
                Add note
              </Button>
            </Form.Item>
          </Space>
        </Form>
        <Timeline reverse items={timelineItems} />
      </Card>
    </div>
  );
};

export default WarehouseOrderActivityCard;
