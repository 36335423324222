import React from 'react';
import { Button, Card, Dropdown, Table, Tag, MenuProps, message, Input } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  FulfillMent,
  IService,
  LineItem,
  WarehouseCarrier
} from '../../../custom_types/warehouse-order-page';
import { FILE_FORMATS, FulfillmentStatus, UI_ROUTES } from '../../../shared/utils/constants';
import FulfillmentLabelModal from './FulfillmentLabelModal';
import { labelFulfillment } from '../../../redux/orders/warehouseOrderSlice';
import { downloadLabelsHandler } from '../../../shared/utils/pdf.helpers';

interface FulfillmentCardProps {
  orderId: string;
  fulfillment: FulfillMent;
  index: number;
  updateFulfillments: (i: number, fulfillments: FulfillMent[]) => void;
}

const FulfillmentCard = ({
  orderId,
  fulfillment,
  index,
  updateFulfillments
}: FulfillmentCardProps) => {
  const dispatch = useDispatch();
  const [isSplit, setIsSplit] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState<LineItem[]>([]);
  const history = useHistory();

  const tableRowSelected = (rowKey: string) => {
    return selectedItems.find((item) => item.physicalProperties.sku === rowKey);
  };

  const tableRowQuantityChange = (rowKey: string, value: number) => {
    const newSelectedItem: LineItem[] = [];
    selectedItems.forEach((item) => {
      if (item.physicalProperties.sku === rowKey) {
        const newItem = { ...item, quantity: value };
        newSelectedItem.push(newItem);
      } else {
        newSelectedItem.push(item);
      }
    });
    setSelectedItems(newSelectedItem);
  };

  const columns = [
    {
      key: 'image',
      render: (_: any, record: LineItem) => (
        <img
          src={record.image?.url || 'https://via.placeholder.com/150'}
          alt={record.productName.original}
          style={{ width: '50px', height: '50px' }}
        />
      )
    },
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    // {
    //   title: 'Type',
    //   key: 'lineItemType',
    //   dataIndex: 'lineItemType'
    // },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (_: any, record: LineItem) => {
        if (isSplit && tableRowSelected(record.physicalProperties.sku)) {
          return (
            <Input
              type="number"
              defaultValue={record.quantity}
              min={1}
              max={record.quantity}
              suffix={`of ${record.quantity}`}
              onChange={(e) =>
                tableRowQuantityChange(record.physicalProperties.sku, Number(e.target.value))
              }
            />
          );
        }
        return record.quantity;
      }
    }
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setIsSplit(true);
  };

  const handleSplitFulfillment = () => {
    if (selectedItems.length === 0) {
      message.error('Please select items to split');
      return;
    }
    // 1. create new fulfillment from selectedItems
    const newFulfillment: FulfillMent = {
      status: FulfillmentStatus.NOT_FULFILLED,
      location: fulfillment.location,
      dateCreated: new Date(),
      items: selectedItems
    };
    // 2. create another new fulfillment from remaining items
    const remianingItems: LineItem[] = [];
    fulfillment.items.forEach((item) => {
      const found = selectedItems.find(
        (selectedItem) => selectedItem.physicalProperties.sku === item.physicalProperties.sku
      );
      if (!found) {
        remianingItems.push(item);
      } else {
        const quantity = item.quantity - found.quantity;
        if (quantity !== 0) {
          remianingItems.push({ ...item, quantity });
        }
      }
    });
    if (remianingItems.length === 0) {
      message.error('Cannot split the fulfillment.');
      return;
    }
    const anotherFulfillment: FulfillMent = {
      status: FulfillmentStatus.NOT_FULFILLED,
      location: fulfillment.location,
      dateCreated: new Date(),
      items: remianingItems
    };
    updateFulfillments(index, [anotherFulfillment, newFulfillment]);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Split Fulfillment',
      key: '1'
    }
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick
  };

  const getItemsCount = () => {
    let count = 0;
    fulfillment.items.forEach((item) => {
      count += item.quantity;
    });
    return count;
  };

  const cardTitle = () => {
    return (
      <div>
        <span>
          {fulfillment.status === FulfillmentStatus.FULFILLED ? (
            <Tag color="geekblue">
              <CheckCircleOutlined rev={undefined} /> Fulfilled({getItemsCount()}){' '}
            </Tag>
          ) : (
            <Tag color="yellow">Unfulfilled({getItemsCount()})</Tag>
          )}
        </span>
        {fulfillment.status !== FulfillmentStatus.FULFILLED && (
          <span style={{ float: 'right' }}>
            <Dropdown menu={menuProps}>
              <Button type="text">...</Button>
            </Dropdown>
          </span>
        )}
      </div>
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: LineItem[]) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedItems([...selectedRows]);
    }
  };

  const labelFulfillHandler = (warehouseCarrier: WarehouseCarrier, service: IService) => {
    dispatch(labelFulfillment({ warehouseCarrier, service, orderId, index, fulfillment }));
    setOpenModal(false);
  };

  return (
    <div>
      {openModal && (
        <FulfillmentLabelModal
          show={openModal}
          onClose={() => setOpenModal(false)}
          onConfirm={(warehouseCarrier: WarehouseCarrier, service: IService) =>
            labelFulfillHandler(warehouseCarrier, service)
          }
        />
      )}
      <Card size="small" title={cardTitle()} style={{ marginBottom: '20px' }}>
        <p>Location</p>
        <p>{fulfillment.location}</p>
        {fulfillment.status === FulfillmentStatus.FULFILLED && (
          <>
            <p>Fulfilled</p>
            <p>{dayjs(fulfillment.dateCreated).format('MMM DD, YYYY HH:mm A')}</p>
            <p>{fulfillment.trackingInfo?.shippingProvider}</p>
            <p>{fulfillment.trackingInfo?.trackingNumber}</p>
            {fulfillment.trackingInfo && fulfillment.trackingInfo.lable && (
              <Button
                onClick={() =>
                  downloadLabelsHandler([fulfillment.trackingInfo!.lable], FILE_FORMATS.thermal)
                }
                type="link"
              >
                Print Label
              </Button>
            )}
          </>
        )}
        <Table<LineItem>
          rowSelection={
            isSplit
              ? {
                  type: 'checkbox',
                  ...rowSelection
                }
              : undefined
          }
          columns={columns}
          dataSource={fulfillment.items}
          rowKey={(record) => record.physicalProperties.sku}
          pagination={false}
        />
        {fulfillment.status !== FulfillmentStatus.FULFILLED && (
          <div>
            {isSplit ? (
              <>
                <Button
                  type="primary"
                  style={{ float: 'right', marginTop: '10px' }}
                  onClick={() => handleSplitFulfillment()}
                >
                  Split fulfillment
                </Button>
                <Button
                  type="default"
                  style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}
                  onClick={() => setIsSplit(false)}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="primary"
                  style={{ float: 'right', marginTop: '10px' }}
                  onClick={() => setOpenModal(!openModal)}
                >
                  Create shipping label
                </Button>
                <Button
                  type="default"
                  style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}
                  onClick={() =>
                    history.push(`${UI_ROUTES.ORDERS}${UI_ROUTES.FULLFILL}/${orderId}/${index}`)
                  }
                >
                  Fulfill items
                </Button>{' '}
              </>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export default FulfillmentCard;
