import React, { useEffect, useState } from 'react';
import { Button, Table, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SyncOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import columns from '../components/TableColumns/WarehouseOrderColumn';

import {
  selectWarehouseOrders,
  selectLoading,
  fetchWarehouseOrders,
  fetchWarehouseCarriers
} from '../../../redux/orders/warehouseOrderSlice';
import { WarehouseOrder } from '../../../custom_types/warehouse-order-page';
import {
  FulfillmentStatus,
  PaymentStatus,
  UI_ROUTES,
  WixOrderStatus
} from '../../../shared/utils/constants';
import NoData from '../../../shared/components/NoData';

const { TabPane } = Tabs;

const WarehouseOrderPage = () => {
  const dispatch = useDispatch();
  const warehouseOrders = useSelector(selectWarehouseOrders);
  const loading = useSelector(selectLoading);
  const history = useHistory();
  const [curPaymentStatus, setCurPaymentStatus] = useState<string>(PaymentStatus.PAID);
  const [curFulfillmentStatus, setCurFulfillmentStatus] = useState<string>(
    FulfillmentStatus.NOT_FULFILLED
  );

  useEffect(() => {
    dispatch(
      fetchWarehouseOrders({
        paymentStatus: PaymentStatus.PAID,
        fulfillmentStatus: FulfillmentStatus.NOT_FULFILLED,
        status: WixOrderStatus.APPROVED
      })
    );
    dispatch(fetchWarehouseCarriers());
  }, [dispatch]);

  const tabChangeHandler = (activeKey: string) => {
    if (activeKey === 'unfulfilled') {
      setCurPaymentStatus(PaymentStatus.PAID);
      setCurFulfillmentStatus(FulfillmentStatus.NOT_FULFILLED);
      dispatch(
        fetchWarehouseOrders({
          paymentStatus: PaymentStatus.PAID,
          fulfillmentStatus: FulfillmentStatus.NOT_FULFILLED,
          status: WixOrderStatus.APPROVED
        })
      );
    } else if (activeKey === 'fulfilling') {
      setCurPaymentStatus(PaymentStatus.PAID);
      setCurFulfillmentStatus(FulfillmentStatus.FULFILLING);
      dispatch(
        fetchWarehouseOrders({
          paymentStatus: PaymentStatus.PAID,
          fulfillmentStatus: FulfillmentStatus.FULFILLING,
          status: WixOrderStatus.APPROVED
        })
      );
    } else {
      setCurPaymentStatus(PaymentStatus.PAID);
      setCurFulfillmentStatus(FulfillmentStatus.FULFILLED);
      dispatch(
        fetchWarehouseOrders({
          paymentStatus: PaymentStatus.PAID,
          fulfillmentStatus: FulfillmentStatus.FULFILLED,
          status: WixOrderStatus.APPROVED
        })
      );
    }
  };

  return (
    <div>
      <div>
        <Button
          style={{ float: 'right' }}
          type="default"
          onClick={() =>
            dispatch(
              fetchWarehouseOrders({
                paymentStatus: curPaymentStatus,
                fulfillmentStatus: curFulfillmentStatus,
                status: WixOrderStatus.APPROVED
              })
            )
          }
        >
          <SyncOutlined rev={undefined} />
        </Button>
        <Tabs defaultActiveKey="unfulfilled" onChange={tabChangeHandler}>
          <TabPane tab="Unfulfilled" key="unfulfilled" />
          <TabPane tab="Fulfilling" key="fulfilling" />
          <TabPane tab="Fulfilled" key="fulfilled" />
        </Tabs>
      </div>

      <Table<WarehouseOrder>
        scroll={{ x: 'max-content' }}
        rowKey={(record: WarehouseOrder) => record.id}
        columns={[
          {
            title: 'Order',
            key: 'orderId',
            dataIndex: 'orderId',
            render: (text: string, record: WarehouseOrder) => {
              return (
                <Button
                  type="link"
                  onClick={() =>
                    history.push(`${UI_ROUTES.ORDERS}${UI_ROUTES.DETAIL}/${record.id}`)
                  }
                >
                  {record.id}
                </Button>
              );
            }
          },
          ...columns
        ]}
        dataSource={warehouseOrders}
        loading={loading}
        locale={{
          emptyText: <NoData />
        }}
      />
    </div>
  );
};

export default WarehouseOrderPage;
