import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, Col, Divider, Result, Row, Spin, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { FulfillMent, WarehouseOrder } from '../../../custom_types/warehouse-order-page';
import {
  selectWarehouseOrders,
  updateFulfillments
} from '../../../redux/orders/warehouseOrderSlice';
import { FulfillmentStatus, PaymentStatus, UI_ROUTES } from '../../../shared/utils/constants';
import WarehouseOrderInfoCard from '../components/WarehouseOrderInfoCard';
import WarehouseOrderNoteCard from '../components/WarehouseOrderNoteCard';
import FulfillmentCard from '../components/FulfillmentCard';
import WarehouseOrderActivityCard from '../components/WarehouseOrderActivityCard';

interface ParamType {
  orderId: string | undefined;
}

const WarehouseOrderDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderId } = useParams<ParamType>();
  const warehouseOrders = useSelector(selectWarehouseOrders);
  const [loading, setLoading] = useState(false);
  const [curOrder, setCurOrder] = useState<undefined | WarehouseOrder>();

  useEffect(() => {
    setLoading(true);
    const order = warehouseOrders.find((item) => item.id === orderId);
    console.log(order);
    if (!order) {
      history.push(UI_ROUTES.ORDERS);
      return;
    }
    setCurOrder(order);
    setLoading(false);
  }, [warehouseOrders, orderId, history]);

  const splitFulfillment = (index: number, newFulfillments: FulfillMent[]) => {
    if (curOrder) {
      const fulfillmentsCopy = [...curOrder.fulfillments];
      const newList: FulfillMent[] = [];
      fulfillmentsCopy.forEach((fulfillment, i) => {
        if (i === index) {
          newList.push(...newFulfillments);
        } else {
          newList.push(fulfillment);
        }
      });
      dispatch(updateFulfillments({ id: curOrder.id, newList }));
    }
  };

  const generatePaymentStatusTab = (paymentStatus: string) => {
    const text = paymentStatus === PaymentStatus.PAID ? 'Paid' : 'Unpaid';
    return (
      <Tag color={paymentStatus === PaymentStatus.PAID ? '' : 'yellow'}>
        <FormattedMessage id={text} />
      </Tag>
    );
  };

  const generateFulfillmentStatusTab = (fulfillmentStatus: string) => {
    let text = 'Unfulfilled';
    if (fulfillmentStatus === FulfillmentStatus.FULFILLED) {
      text = 'Fulfilled';
    }
    if (fulfillmentStatus === FulfillmentStatus.FULFILLING) {
      text = 'Fulfilling';
    }
    let colorText = '';
    if (fulfillmentStatus === FulfillmentStatus.NOT_FULFILLED) {
      colorText = '#fb7d33';
    }
    if (fulfillmentStatus === FulfillmentStatus.FULFILLING) {
      colorText = 'yellow';
    }
    return (
      <Tag color={colorText}>
        <FormattedMessage id={text} />
      </Tag>
    );
  };

  return (
    <div style={{ marginLeft: '100px', marginRight: '100px' }}>
      <div>
        <Breadcrumb style={{ maxWidth: '200px', float: 'left', marginTop: '10px' }}>
          <Breadcrumb.Item>
            <Link to={UI_ROUTES.ORDERS}>
              <FormattedMessage id="orders" />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item># {curOrder?.number}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Divider />
      <Spin spinning={loading} size="large" style={{ margin: 'auto' }}>
        <div>
          {curOrder ? (
            <>
              <h1>
                Order # {curOrder.number} {generatePaymentStatusTab(curOrder.paymentStatus)}{' '}
                {generateFulfillmentStatusTab(curOrder.fulfillmentStatus)}
              </h1>
              <span>Placed on {dayjs(curOrder.createdDate).format('MMM DD, YYYY HH:mm A')}</span>
              <Row style={{ marginTop: '30px' }} gutter={20}>
                <Col span={16}>
                  {curOrder.fulfillments.map((fulfillment, index) => (
                    <FulfillmentCard
                      key={uuidv4()}
                      orderId={curOrder.id}
                      fulfillment={fulfillment}
                      index={index}
                      updateFulfillments={splitFulfillment}
                    />
                  ))}
                  <WarehouseOrderActivityCard order={curOrder} />
                </Col>
                <Col span={8}>
                  <WarehouseOrderNoteCard order={curOrder} />
                  <WarehouseOrderInfoCard order={curOrder} />
                </Col>
              </Row>
            </>
          ) : (
            <Result status="warning" title="No Order Found" />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default WarehouseOrderDetailPage;
