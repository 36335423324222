import React from 'react';
import { Card } from 'antd';
import { WarehouseOrder } from '../../../custom_types/warehouse-order-page';

interface WarehouseOrderNoteCardProps {
  order: WarehouseOrder;
}

const WarehouseOrderNoteCard = ({ order }: WarehouseOrderNoteCardProps) => {
  return (
    <div>
      <Card size="small" title="Notes" style={{ marginBottom: '20px' }}>
        <p>{order.buyerNote ? order.buyerNote : 'No notes from customer'}</p>
      </Card>
    </div>
  );
};

export default WarehouseOrderNoteCard;
