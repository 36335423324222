import { Button, Form, Input, Space, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import React, { ReactElement } from 'react';
import validateLib from 'validator';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { UserUpdateFormData } from '../../../../custom_types/profile-page';
import {
  selectCurUser,
  selectProfilePageLoading,
  updateUserHandler
} from '../../../../redux/user/userSlice';
import PasswordDescription from '../../../../shared/components/PasswordDescription';

const ProfilePage = (): ReactElement => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const loading = useSelector(selectProfilePageLoading);
  const user = useSelector(selectCurUser);

  const formSubmitHandler = (values: UserUpdateFormData) => {
    if (user) {
      dispatch(updateUserHandler({ id: user.id, ...values }));
    }
  };

  return (
    <div>
      <PageHeader title={<FormattedMessage id="profile" />} />
      <div>
        <Form
          initialValues={user}
          style={{ textAlign: 'center', width: '70%', marginLeft: '15%' }}
          layout="vertical"
          onFinish={formSubmitHandler}
        >
          <Form.Item
            label={<FormattedMessage id="name" />}
            name="name"
            rules={[
              {
                required: true,
                message: (
                  <>
                    <FormattedMessage id="name" />
                    <FormattedMessage id="requiredField" />
                  </>
                )
              }
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item label={<FormattedMessage id="company" />} name="company">
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id="email" />}
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <>
                    <FormattedMessage id="email" />
                    <FormattedMessage id="requiredField" />
                  </>
                )
              },
              () => ({
                validator(rule, value) {
                  if (!value || validateLib.isEmail(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `${intl.formatMessage({
                        id: 'email'
                      })}${intl.formatMessage({ id: 'invalidFormat' })}`
                    )
                  );
                }
              })
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id="curPassword" />}
            name="password"
            rules={[
              {
                required: true,
                message: <FormattedMessage id="needPassword" />
              }
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={
              <Space size="small">
                <FormattedMessage id="newPassword" />
                <Tooltip placement="topLeft" title={PasswordDescription}>
                  <QuestionCircleOutlined rev={undefined} />
                </Tooltip>
              </Space>
            }
            name="newPassword"
            rules={[
              () => ({
                validator(rule, value) {
                  if (!value || validateLib.isStrongPassword(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(intl.formatMessage({ id: 'weekPassword' })));
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id="confirmPassword" />}
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(intl.formatMessage({ id: 'passNotMatch' })));
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button style={{ float: 'left' }} type="primary" htmlType="submit" loading={loading}>
              <FormattedMessage id="save" />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ProfilePage;
